@import '../../../stylesheets/basics/layout-variables';

// Color Variables
$color-primary: #ff3b30;
$text-color: #2f2f2f;
$light-gray: #f0f0f0;
$gray: #ccc;
$dark-gray: #a6a6a6;
$marketing-green: #7ab637;
$marketing-green-light: #edf6e2;
$special-color: #ffea00;
$color-primary-dark: darken($color-primary, 10%);

$menu-neutral-light: #F7F6F4;
$menu-neutral-dark: #C6C1BA;

// Misc Variables
$nav-border-top: .3125rem solid $color-primary;
$footer-logo-border: .125rem solid $color-primary;


// Typography Variables
$font-primary: 'Open Sans', sans-serif;